import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/font/google/target.css?{\"path\":\"src/global/fonts.ts\",\"import\":\"IBM_Plex_Sans_Hebrew\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"sans-serif\"],\"variable\":\"--font-secondary\"}],\"variableName\":\"IBM_Plex_Sans_Hebrew\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next/src/components/_global/CookieConsent/_Content.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/global/global.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/font/google/target.css?{\"path\":\"src/global/fonts.ts\",\"import\":\"Saira\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"fallback\":[\"sans-serif\"],\"display\":\"swap\"}],\"variableName\":\"Saira\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_landing/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/CookieConsent/CookieConsent.module.scss");
